<template>
  <div class="shopclaim">
    <div class="shopclaim__wrap">
      <div class="shopclaim__item shopclaim__item--img">
        <img src="/images/crujientes.png" alt="Crujientes">
      </div>
      <div class="shopclaim__item shopclaim__item--text">
        <h2>{{$t('shop.claim')}}</h2>
        <p>{{$t('shop.claim-2')}}</p>
        <a href="https://tienda.panaderiajesus.com/">{{$t('shop.go-shopping')}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShopClaim',
};
</script>

<style lang="scss">
.shopclaim {
  padding: 40px 20px 20px;
  background: white;
  clip-path: polygon(50% 0%, 100% 30px, 100% 100%, 0 100%, 0 30px);

  &__wrap {
    text-align: center;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
  }

  &__item {
    &--img {
      display: none;
    }
    &--text a {
      &:after {
        content: ">";
        font-size: 28px;
        line-height: 0;
        margin-top: 0px;
        margin-left: 5px;
        display: inline-block;
        vertical-align: middle;
        transition: transform 250ms ease;
      }
      &:hover:after {
        transform: translateX(6px);
      }
    }
  }
  h2 {
    margin-top: 10px;
    font-size: 30px;
    margin-bottom: 10px;
  }
  p {
    color: #E29C00;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 980px) {
  .shopclaim {
    &__wrap {
      display: flex;
      flex-wrap: nowrap;
    }
    &__item {
      &--img {
        display: block;
        flex: 1 1 60%;
      }
      &--text {
        flex: 1 1 40%;
        text-align: left;
        margin-left: 40px;
      }
    }
    img {
      width: 100%;
    }
  }
}
</style>
