<!-- eslint-disable max-len -->
<template>
  <div class="page page--panaderia">

    <Hero :title="$t('family.hero')" image="head-familia-panadera.jpg"></Hero>

    <Family/>

    <div class="page__videos">
      <h2>{{$t('family.claim')}}</h2>

      <div class="page__videos-block page__videos-block--nopad">
        <div class="pad">
          <p>{{$t('family.desc-1')}}</p>
          <p>{{$t('family.desc-2')}}</p>
        </div>
        <div class="bg" style="background-image: url(/images/primer-pan.jpg)"></div>
      </div>

      <div class="page__iframe">
        <div class="page__iframe-wrap">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/u0MxGcpqY9k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>

      <div class="page__videos-block page__videos-block--nopad">
        <div class="bg" style="background-image: url(/images/generacion-7.jpg)">
        </div>
        <div class="pad">
          <p>{{$t('family.desc-3')}}</p>
          <p>{{$t('family.desc-4')}}</p>
        </div>
      </div>
    </div>

    <div class="premios">
      <h2>{{$t('family.awards')}}</h2>
      <div class="premios__items">
        <img src="/images/Premio-1-AJE-2013.png" alt="AJE">
        <img src="/images/Premio-2-CEAJE-2018.png" alt="Ceaje">
        <img src="/images/Premio-3-CdC-2019.png" alt="CdC">
      </div>
    </div>

    <div class="shopcolor">
      <ShopClaim/>
    </div>

  </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Family from '@/components/Family.vue';
import ShopClaim from '@/components/ShopClaim.vue';

export default {
  name: 'Panaderia',
  components: {
    Hero,
    Family,
    ShopClaim,
  },
};
</script>

<style lang="scss">
.shopcolor {
  background: #A41B1A;
}
.premios {
  background: #A41B1A;
  color: white;
  padding: 20px;
  h2 {
    color: white;
    text-align: center;
  }

  &__items {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img {
      width: 180px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (min-width: 480px) {
  .premios {
    padding: 40px;

    &__items {
      flex-wrap: wrap;
      flex-direction: row;
      img {
        width: 140px;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (min-width: 940px) {
  .premios {
    &__items {
      justify-content: space-between;
      max-width: 800px;
      margin: 0 auto;
    }
    img {
      width: 250px;
    }
  }
}
</style>
