<template>
  <div class="generations">
    <h2>{{$t('family.generations')}}</h2>
    <div class="generations__wrap">
      <div v-for="(gen, i) in generations" :key="i" class="generations__item">
        <div class="generations__imgs">
          <img :src="`/images/${gen.img1}`">
          <img :src="`/images/${gen.img2}`">
        </div>
        <div class="generations__names">
          {{gen.names}}
        </div>
        <div class="generations__gen">
          {{$t(`family.${gen.title}`)}}
        </div>
        <div class="generations__year" v-if="gen.year">
          {{gen.year}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Family',
  data() {
    return {
      generations: [{
        names: 'Santiago López y Mercedez Vázquez',
        title: '1-gen',
        year: 1802,
        img1: '1-santiago-lopez.jpg',
        img2: '1-mercedes-vazquez.jpg',
      }, {
        names: 'Santiago López y Matilde Martínez',
        title: '2-gen',
        year: undefined,
        img1: '2-santiago-lopez.jpg',
        img2: '2-matilde-martinez.jpg',
      }, {
        names: 'Pedro López y Delfina Reyes',
        title: '3-gen',
        year: undefined,
        img1: '3-pedro-lopez.jpg',
        img2: '3-delfina-reyes.jpg',
      }, {
        names: 'Juan Miguel López y Antonia Motilla',
        title: '4-gen',
        year: undefined,
        img1: '4-juan-miguel-lopez.jpg',
        img2: '4-antonia-motilla.jpg',
      }, {
        names: 'Eliecer López y Ester Fernández',
        title: '5-gen',
        year: undefined,
        img1: '5-eliecer-lopez.jpg',
        img2: '5-ester-fernandez.jpg',
      }, {
        names: 'Jesús López y Angelina Casado',
        title: '6-gen',
        year: undefined,
        img1: '6-jesus-lopez.jpg',
        img2: '6-angelina-casado.jpg',
      }, {
        names: 'Jesús López y Rubén López',
        title: '7-gen',
        year: undefined,
        img1: '7-jesus-lopez.jpg',
        img2: '7-ruben-lopez.jpg',
      }],
    };
  },
};
</script>

<style lang="scss">
.generations {
  background: #F6F6F6;
  overflow: hidden;
  color: #E29C00;
  clip-path: polygon(100% 0, 100% calc(100% - 200px), 50% 100%, 0 calc(100% - 200px), 0 0);
  padding-bottom: 40px;

  h2 {
    padding: 60px 20px 20px;
    text-align: center;
  }

  &__wrap {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1040px;
    margin: 40px auto;
  }

  &__item {
    flex: 1 0 100%;
    text-align: center;
    margin-bottom: 20px;
    max-width: 240px;
  }
  &__imgs {
    margin-bottom: 10px;
    img {
      width: 90px;
      height: auto;
      border-radius: 50%;
      border: 4px solid #E29C00;
      + img {
        margin-left: -10px;
      }
    }
  }
  &__names {
    margin-bottom: 4px;
    font-size: 12px;
    color: #AAA;
    font-weight: 700;
  }
  &__gen {
    margin-bottom: 4px;
    color: #E29C00;
    font-weight: 700;
    font-size: 16px;
  }
  &__year {
    color: #E29C00;
    opacity: 0.6;
  }
}
</style>
